// ** Auth Endpoints
import themeConfig from "../../../configs/themeConfig"
export default {
  loginEndpoint: `${themeConfig.app.apiUrl}/auth/login`,
  registerEndpoint: `${themeConfig.app.apiUrl}/auth/register`,
  refreshEndpoint: `${themeConfig.app.apiUrl}/auth/refresh`,
  logoutEndpoint: `${themeConfig.app.apiUrl}/auth/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}
