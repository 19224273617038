// You can customize the template with the help of this file

//Template config options
const server = {
  LOCAL:"http://localhost/sutherlin-api/public",
  STAGING: "https://sautomotive.livechatlog.com/api/public",
  PROD: ""
}
const themeConfig = {
  app: {
    appName: "S. Automotive",
    apiUrl:server[process.env.REACT_APP_ENV],
    appLogoImage: require("@src/assets/images/logo/logo.svg").default,
    storageUrl: `${server[process.env.REACT_APP_ENV]}/storage`,
  },
  allowances: {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 5,
    "5": 6,
    "6": 7,
    "7": 8,
    "8": 9,
    "9": 10,
    "10": 15
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "static" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  },
  server,
  formats: {
    date: 'LL',
    both: 'LLL',
    time: 'hh:mm A',
  },
  mysqlFormats: {
    date: 'YYYY-MM-DD'
  }
}
export default themeConfig
